import {
  CalculateInstallmentsMX,
  CashMethods,
  ExternalPaymentMethod,
  ExternalPaymentMethods,
  SaveCard,
} from "@cloudpayments/api-client";
import { Logger, Utils } from "@cloudpayments/vue-utils";
import { TerminalInfoResponseModel } from "@cloudpayments/api-client/src/contracts/response/TerminalInfoResponse";
import { TerminalInfo } from "@src/contracts/TerminalInfo";

export interface IOrderItems {
  items: IOrderItem[];
}

export interface IOrderItem {
  label: string;
  price: string;
  quantity: number;
  amount: string;
}

export enum RecurrentInterval {
  Week = 0,
  Month = 1,
  Day = 2,
}

export interface IOrderSubscription {
  period: number;
  recurrentInterval: RecurrentInterval;
}

export enum CardTransactionScheme {
  Single = 0,
  Dual = 1,
}

export interface IOrderOptions {
  orderNumber?: string;
  cultureName: string;
  terminalName: string;
  terminalPublicId: string;
  terminalMode: string;
  amount: number;
  email: string;
  currency: string;
  title: string;
  description: string;
  items: IOrderItems;
  subscription?: IOrderSubscription;
  linkToOffer: string;
  paid: boolean;
  failRedirectUrl?: string;
  successRedirectUrl?: string;
  transactionId?: number;
  phone?: string;
  accountId?: string;
  cardTransactionMessageScheme?: CardTransactionScheme;
  hasAccountId: boolean;
  isReccurent: boolean;
  merchantConfiguration: TerminalInfoResponseModel;
}

export class OrderOptions implements IOrderOptions {
  public publicOrderId: string;
  public orderNumber: string;
  public cultureName: string;
  public terminalName: string;
  public terminalMode: string;
  public terminalPublicId: string;
  public terminalLogoUrl: string;
  public externalPaymentMethods: ExternalPaymentMethod[];
  public isTest: boolean;
  public amount: number;
  public currency: string;
  public title: string;
  public description: string;
  public publicId: string;
  public email: string;
  public isCharity: boolean;
  public isCvvRequired: boolean;
  public skipExpiryValidation: boolean;
  public isSaveCard: SaveCard;
  public items: IOrderItems;
  public isOrder: boolean;
  public linkToOffer: string;
  public paid: boolean;
  public terminalInfo: TerminalInfo;
  public subscription?: IOrderSubscription;
  public failRedirectUrl?: string;
  public successRedirectUrl?: string;
  public transactionId?: number;
  public phone?: string;
  public accountId?: string;
  public currencySign: string;
  public cardTransactionMessageScheme?: CardTransactionScheme;
  public hasAccountId: boolean;
  public isReccurent: boolean;
  public merchantConfiguration: TerminalInfoResponseModel;
  public installmentDataMX?: CalculateInstallmentsMX;

  constructor(
    orderOptions: IOrderOptions,
    terminalInfo: TerminalInfoResponseModel,
    isOrder: boolean,
    publicOrderId: string,
    installmentDataMX?: CalculateInstallmentsMX
  ) {
    this.publicOrderId = publicOrderId;
    this.orderNumber = orderOptions.orderNumber || "";
    this.cultureName = orderOptions.cultureName;
    this.terminalName = orderOptions.terminalName;
    this.terminalPublicId = orderOptions.terminalPublicId;
    this.terminalLogoUrl = terminalInfo.LogoUrl;
    this.externalPaymentMethods = terminalInfo.ExternalPaymentMethods;
    this.isTest = terminalInfo.IsTest;
    this.skipExpiryValidation = terminalInfo.SkipExpiryValidation;
    this.isSaveCard = terminalInfo.Features?.IsSaveCard;
    this.amount = orderOptions.amount;
    this.currency = orderOptions.currency;
    this.title = orderOptions.title;
    this.description = orderOptions.description;
    this.publicId = orderOptions.terminalPublicId;
    this.email = orderOptions.email;
    this.isCharity = terminalInfo.IsCharity;
    this.isCvvRequired = terminalInfo.IsCvvRequired;
    this.items = orderOptions.items;
    this.isOrder = isOrder;
    this.linkToOffer = orderOptions.linkToOffer;
    this.paid = orderOptions?.paid || false;
    this.subscription = orderOptions.subscription;
    this.successRedirectUrl = orderOptions.successRedirectUrl;
    this.failRedirectUrl = orderOptions.failRedirectUrl;
    this.transactionId = orderOptions.transactionId;
    this.phone = orderOptions.phone;
    this.accountId = orderOptions.accountId;
    this.terminalInfo = new TerminalInfo(terminalInfo);
    this.terminalMode = terminalInfo.IsTest ? "test" : "prod";
    this.currencySign = Utils.getCurrencySymbol(orderOptions.currency);
    this.cardTransactionMessageScheme =
      orderOptions.cardTransactionMessageScheme;
    Logger.LogInfo("orderOptions", orderOptions);
    this.hasAccountId = orderOptions.hasAccountId;
    this.isReccurent = orderOptions.isReccurent;
    this.merchantConfiguration = orderOptions.merchantConfiguration;
    this.installmentDataMX = installmentDataMX;
  }

  public allowedMethods(): ExternalPaymentMethods[] {
    return (
      this.externalPaymentMethods
        .filter((method) => method.Enabled)
        .map((method) => method.Type) || []
    );
  }

  async isYandexPayAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.YandexPay);
  }

  public async isApplePayAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.ApplePay);
  }

  public async isGooglePayAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.GooglePay);
  }

  public async isTinkoffPayAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.TinkoffPay);
  }

  public async isSberPayAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.SberPay);
  }

  public async isSpeiAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.Spei);
  }

  public async isCashAvailable(): Promise<boolean> {
    const cashSettings = this.getCashSettings();

    return (
      this.allowedMethods().includes(ExternalPaymentMethods.Cash) &&
      (!!cashSettings?.CashMethods?.includes(CashMethods.Oxxo) ||
        !!cashSettings?.CashMethods?.includes(CashMethods.CStores) ||
        !!cashSettings?.CashMethods?.includes(CashMethods.Pharmacy))
    );
  }

  public getCashSettings(): ExternalPaymentMethod | undefined {
    return this.externalPaymentMethods.find(
      (item) => item.Type === ExternalPaymentMethods.Cash
    );
  }

  public async isSomAvailable(): Promise<boolean> {
    return (
      this.allowedMethods().includes(ExternalPaymentMethods.Som) &&
      this.amount >= 10
    );
  }

  public async isDolyameAvailable(): Promise<boolean> {
    const dolyameSettings = this.externalPaymentMethods.find(
      (item) => item.Type === ExternalPaymentMethods.TcsBnplDolyame
    );
    return (
      (!dolyameSettings?.MinSum || this.amount >= dolyameSettings?.MinSum) &&
      (!dolyameSettings?.MaxSum || this.amount <= dolyameSettings?.MaxSum) &&
      this.allowedMethods().includes(ExternalPaymentMethods.TcsBnplDolyame)
    );
  }

  public async isTinkoffInstallmentAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(
      ExternalPaymentMethods.TinkoffInstallmentPay
    );
  }

  public async isTinkoffCreditAvailable(): Promise<boolean> {
    return this.allowedMethods().includes(ExternalPaymentMethods.Loan);
  }

  public isSbpAvailable(): boolean {
    return this.allowedMethods().includes(ExternalPaymentMethods.SbpPay);
  }

  public isInstallmentMxAvailable() {
    return this.allowedMethods().includes(
      ExternalPaymentMethods.CardInstallmentMX
    );
  }

  public update(
    order: Partial<
      Pick<IOrderOptions, "transactionId" | "paid" | "email" | "accountId">
    >
  ) {
    if (order.transactionId) {
      this.transactionId = order.transactionId;
    }
    if (order.paid) {
      this.paid = order.paid;
    }

    if (order.email) {
      this.email = order.email;
    }

    if (order.accountId) {
      this.accountId = order.accountId;
    }
  }

  public getBackOptions() {
    return {
      Success: this.paid ? "True" : "False",
      TransactionId: String(this.transactionId),
      InvoiceId: String(this.orderNumber),
      Amount: String(this.amount),
      Currency: String(this.currency),
      Description: String(this.description),
      Email: String(this.email),
      AccountId: String(this.accountId),
      Phone: String(this.phone),
    };
  }

  isSingleMessageScheme(): boolean {
    return this.cardTransactionMessageScheme === CardTransactionScheme.Single;
  }

  isDualMessageScheme(): boolean {
    return this.cardTransactionMessageScheme === CardTransactionScheme.Dual;
  }
}
