<template src="./PaymentProcess.html"></template>
<script lang="ts">
import {
  ComponentInternalInstance,
  computed,
  defineComponent,
  getCurrentInstance,
  inject,
  nextTick,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  PropType,
  reactive,
  ref,
  shallowRef,
  watch,
} from "vue";

import { AppService } from "@src/services/AppService";

import {
  AltPayType,
  BinInfoResponseModel,
  CashMethods,
  CreateAltPayResponse,
  ExternalPaymentMethods,
  InstallmentConfigItem,
  PayResponse,
  SaveCard,
} from "@cloudpayments/api-client";
import SbpProcess from "@src/components/SbpProcess/SbpProcess.vue";

import {
  Alert,
  AppleIcon,
  AttentionIcon,
  Button,
  Card,
  CardType,
  Checkbox,
  DolyameIcon,
  GoogleIcon,
  Icon,
  InfoIndicatorCash,
  InfoIndicatorCashBig,
  LoaderService,
  ModalService,
  ModalType,
  OxxoLogo,
  PharmacyFirstLogo,
  PharmacySecondLogo,
  Process,
  QuestionIcon,
  SberPayIcon,
  SberPayLogoSmall,
  Sbp3Icon,
  SelectV2,
  SevenElevenLogo,
  Step,
  Text,
  TextField,
  TinkoffCreditIcon,
  TinkoffPayIcon,
  TinkoffPayIconBig,
  TinkoffPayLabel,
  Tooltip,
  WaitLink,
  WallmartLogo,
  YandexPayIcon,
} from "@cloudpayments/vue-ui-kit";

import CardForm from "@src/components/CardForm/CardForm.vue";

import { CardData, Receipt, ThreeDs } from "@cloudpayments/vue-ui-presets";

import {
  CardUtils,
  DeviceDetector,
  isInstagramWebView,
  isSafari,
  isTelegram,
  IterableTask,
  Logger,
  openWindowCentered,
  PageVisibility,
  Ref,
  telegramRedirect,
  Timer,
  Utils,
} from "@cloudpayments/vue-utils";

import { YandexPayApi } from "@src/services/externalApiServices/YandexPay/YandexPayApi";
import { environment } from "@src/environment";

import {
  PaymentMethodEnum,
  PaymentProcessState,
} from "@src/contracts/PaymentForm";
import { AmplitudeEvents } from "@src/contracts/AmplitudeEvents";

import { OrderOptions } from "@src/contracts/OrderOptions";
import { SbpResultStatus } from "@src/services/externalApiServices/SBP/sbpApi";
import { QrMode } from "@src/enums";

import {
  CardDataServiceKey,
  DomainConfigurationKey,
  MonitoringServiceKey,
} from "@src/injections";
import { TinkoffPayApi } from "@src/services/externalApiServices/TinkoffPay/tinkoffPayApi";
import Qr from "@src/components/SbpProcess/Qr/Qr.vue";
import { SpeiIcon } from "@src/icons/AsyncIcons";
import WaitBottomSheet from "@src/components/WaitBottomSheet/WaitBottomSheet.vue";
import {
  CardFields,
  ValidationError,
} from "@cloudpayments/checkout-script/lib/types/models";
import { AltPayApi } from "@src/services/externalApiServices/TinkoffPay/altPayApi";
import { Checkout } from "@cloudpayments/checkout-script";
import Spei from "@src/views/Spei/Spei.vue";
import { SessionStorageService } from "@src/services/SessionStorageService";
import { CreateAltPayOrderResponse } from "@src/services/api/models/CreateAltPayOrderResponse";
import { BayonetScriptLoader } from "@cloudpayments/isolated-script-loader";
import { TransactionResultStatus } from "@src/contracts/TransactionResultStatus";
import InstallmentModal from "@src/components/TinkoffInstallment/InstallmentModal/InstallmentModal.vue";
import { TinkoffInstallmentData } from "@src/contracts/TinkoffInstallmentData";
import { SberPayApi } from "@src/services/externalApiServices/SberPay/sberPayApi";
import { CreateAltPayOrderRequest } from "@src/services/api/models/CreateAltPayOrderRequest";
import { PaymentMethodsStatist } from "@src/enums/PaymentMethodsStatist";
import { getBrowserInfo } from "@src/common/Utils";
import useVuelidate from "@vuelidate/core";
import { email, maxLength, required } from "@vuelidate/validators";
import { CardDataService } from "@src/services/CardDataService";

export default defineComponent({
  name: "PaymentProcess",
  components: {
    CardForm,
    Card,
    Text,
    Alert,
    Checkbox,
    Button,
    Receipt,
    Process,
    Step,
    Icon,
    YandexPayIcon,
    AppleIcon,
    GoogleIcon,
    SbpProcess,
    Sbp3Icon,
    AttentionIcon,
    TinkoffPayIcon,
    TinkoffPayIconBig,
    TinkoffPayLabel,
    SberPayIcon,
    QuestionIcon,
    Tooltip,
    WaitBottomSheet,
    SpeiIcon,
    Spei,
    DolyameIcon,
    WaitLink,
    SelectV2,
    TinkoffCreditIcon,
    OxxoLogo,
    SevenElevenLogo,
    WallmartLogo,
    TextField,
    InfoIndicatorCashBig,
    InfoIndicatorCash,
    PharmacyFirstLogo,
    PharmacySecondLogo,
  },
  props: {
    order: Object as PropType<OrderOptions>,
    isRenewalOfSubscription: Boolean,
  },
  emits: ["onSuccess", "onFail", "onChange"],
  setup(props, { emit }) {
    let app: ComponentInternalInstance | null;
    const cardForm = ref();
    const appService = inject<AppService>("AppService")!;
    const cardDataService = inject<CardDataService>(CardDataServiceKey)!;
    const defaultCardData = computed(() => {
      if (!isInstallmentCard.value) {
        return null;
      }

      return cardDataService.cardData;
    });

    const getFormattedAmount = (amount: number) => {
      return Utils.getFormattedAmountWithCurrency({
        amount,
        currencyCode: props.order?.currency,
        firstDelimiter: runtimeConfiguration?.amountSettings?.firstDelimiter,
        secondDelimiter: runtimeConfiguration?.amountSettings?.secondDelimiter,
        locale: runtimeConfiguration?.amountSettings?.locale,
      });
    };

    const loader = inject<LoaderService>("LoaderService");
    const runtimeConfiguration = inject(DomainConfigurationKey)!;
    const modal = inject<ModalService>("ModalService");
    const tinkoffPayApi = inject<TinkoffPayApi>("TinkoffPayApi");
    const sberPayApi = inject<SberPayApi>("SberPayApi");
    const altPayApi = inject<AltPayApi>("AltPayApi");

    const availablePayCount = ref(1);

    const yandexPayButton = ref();

    const cardPay = reactive({
      isLoading: false,
    });
    const yandexPay = reactive({
      isLoading: false,
      isAvailable: false,
      isMounted: false,
    });
    const applePay = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const googlePay = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const tinkoffPay = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const sberPay = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const dolyame = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const spei = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const cash = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const cashCStores = reactive({
      isLoading: false,
      isAvailable: false,
      position: 2,
    });
    const cashPharmacy = reactive({
      isLoading: false,
      isAvailable: false,
      position: 3,
    });
    const cashOxxo = reactive({
      isLoading: false,
      isAvailable: false,
      position: 1,
    });
    const cardInstallment = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const tinkoffInstallment = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const tinkoffCredit = reactive({
      isLoading: false,
      isAvailable: false,
    });
    const sbp = reactive({
      isLoading: false,
      isAvailable: false,
    });

    const som = reactive({
      isLoading: false,
      isAvailable: false,
    });

    const showQRCode = ref(false);
    const qrImage = ref<string>();
    const waitEnabled = ref(true);

    const amount = ref();
    const currency = ref();
    const currencySymbol = ref();
    const locale = ref();
    const process = ref<InstanceType<typeof Process>>();
    const showEmailField = ref(false);
    const state = reactive<PaymentProcessState>({
      success: false,
      paymentMethod: null,
      reasonCode: null,
    });
    const offerLink = ref();
    const privacyPoliceLink = ref();
    const agreementLink = ref();
    const staticAgreementLink = ref();
    const staticOfferLink = ref();
    const isCvvRequired = ref(true);
    const skipExpiryValidation = ref(true);
    const showMoreMethodsButton = ref(true);

    const showSaveCardTooltip = ref(false);
    const showForceSaveCardTooltip = ref(false);

    const shouldShowForceSaveHint = ref();
    const shouldShowSaveCard = ref();
    const sessionStorageService = inject<SessionStorageService>(
      "SessionStorageService"
    );

    const isSpei = ref(false);
    const altPayResponseData = ref<CreateAltPayOrderResponse>();
    const terminalName = ref<string>();

    const cardNumberCountry = ref<number | null>();
    const isCardNumberCountryChanged = ref<boolean>(false);
    const isMirCard = ref<boolean>(false);
    const cardNumber = ref<string | null>(null);
    let cardNameVal: string | null = null;

    const monitoring = inject(MonitoringServiceKey);

    const isInstallmentCard = ref(false);

    const periods = ref();
    const formInstallmentMX = reactive<{
      period: string | null;
      cardData: CardData | null;
      binInfo: BinInfoResponseModel | null;
      isCardDataValid: boolean | null;
      isBankDataAvailable: boolean | null;
    }>({
      period: null,
      cardData: null,
      binInfo: null,
      isCardDataValid: null,
      isBankDataAvailable: null,
    });

    const isInstallmentAlert = computed(
      () => formInstallmentMX.isBankDataAvailable === false
    );

    // Cash pay settings
    const cashSettings = computed(() => appService?.getCashSettings());
    const isCashSumAvailable = computed(
      () => amount.value >= (cashSettings.value?.MinAmount || 0)
    );
    // Cash payment form (Name + Email)
    const cashForm = reactive<Record<string, string | null>>({
      email: props.order?.email || appService.email.value || null,
      name: null,
    });
    const cashFormRules = reactive({
      name: { required, maxLength: maxLength(256) },
      email: { required, email },
    });
    const v$ = useVuelidate(cashFormRules, cashForm as any);

    const isValidCashForm = () => {
      v$.value.$validate();
      return !v$.value.$error;
    };

    const barcodeCashLink = ref<string>("#");
    const barcodeCashLinkOpened = ref<boolean>(false);

    const selectedCashMethod = ref<CashMethods>();

    const cashStoreName = computed(() => {
      if (selectedCashMethod.value === CashMethods.CStores) {
        return "convenience";
      }

      if (selectedCashMethod.value === CashMethods.Pharmacy) {
        return "pharmacy";
      }

      if (selectedCashMethod.value === CashMethods.Oxxo) {
        return "oxxo";
      }

      return "";
    });

    onBeforeMount(async () => {
      YandexPayApi.emitter.on("onProcess", (event) => payByYandexPay(event));
      YandexPayApi.emitter.on("onError", (event) => yandexPayError(event));
      YandexPayApi.emitter.on("onAbort", (event) => yandexPayError(event));
    });

    function checkSberPayRedirectUrls() {
      let locationParsed = new URL(window.location.href);

      if (locationParsed.searchParams.has("transactionId")) {
        startSberPayMobileWaitingFlow(
          Number(locationParsed.searchParams.get("transactionId"))
        );
      }

      if (!locationParsed.searchParams.has("linksList")) {
        return;
      }

      sberPay.isLoading = true;

      let unloading = false;

      window.addEventListener("blur", () => {
        let focusListener = window.addEventListener("focus", () => {
          setTimeout(() => {
            if (!unloading) {
              window.close();
            }
          }, 3000);
        });
      });

      window.addEventListener("beforeunload", () => {
        unloading = true;
      });

      let links = JSON.parse(
        locationParsed.searchParams.get("linksList") as string
      );

      let linkToOpen = links.shift();

      locationParsed.searchParams.delete("linksList");

      if (links?.length) {
        locationParsed.searchParams.append("linksList", JSON.stringify(links));
      } else {
        locationParsed.searchParams.delete("transactionId");
      }

      setTimeout(tryOpenLink, 0, linkToOpen, locationParsed.toString());
      setTimeout(tryReload, 100, locationParsed.toString());

      function tryOpenLink(link: string) {
        window.location.href = link;
      }

      function tryReload(link: string) {
        window.location.replace(link);
      }
    }

    onBeforeUnmount(() => {
      YandexPayApi.emitter.off("onProcess");
      YandexPayApi.emitter.off("onError");
      YandexPayApi.emitter.off("onAbort");
    });

    watch(
      () => props.order,
      () => {
        Logger.LogInfo("init");
        init();
        checkPaySystems();
        checkPayLength();

        const vueLang = app?.appContext?.config?.globalProperties;

        periods.value = props.order?.installmentDataMX?.Model?.Configuration
          ?.length
          ? [
              {
                name: `${Utils.getFormattedAmountWithCurrency({
                  amount: props.order?.amount as number,
                  currencyCode: appService.getOrderOptions()
                    ?.currency as string,
                  firstDelimiter:
                    runtimeConfiguration?.amountSettings?.firstDelimiter,
                  secondDelimiter:
                    runtimeConfiguration?.amountSettings?.secondDelimiter,
                  locale: runtimeConfiguration?.amountSettings?.locale,
                })} ${vueLang?.$t("app.process.cardInstallment.fullPayment")}`,
                value: 1,
              },
              ...props.order?.installmentDataMX?.Model?.Configuration.map(
                (item: InstallmentConfigItem) => ({
                  name: `${Utils.getFormattedAmountWithCurrency({
                    amount: item.MonthlyPayment,
                    currencyCode: appService?.getOrderOptions()
                      ?.currency as string,
                    firstDelimiter:
                      runtimeConfiguration?.amountSettings?.firstDelimiter,
                    secondDelimiter:
                      runtimeConfiguration?.amountSettings?.secondDelimiter,
                    locale: runtimeConfiguration?.amountSettings?.locale,
                  })} x ${item.Term} ${vueLang?.$t(
                    "app.process.cardInstallment.interestFreeMonths"
                  )}`,
                  value: item.Term,
                })
              ),
            ]
          : [];
      }
    );

    watch(
      () => yandexPayButton.value,
      async (container: HTMLElement) => {
        Logger.LogInfo("yandex 1");
        Logger.LogInfo("container", container);
        if (container && yandexPay.isMounted) {
          Logger.LogInfo("yandex 2");
          await reinitializeYandexPaySession();
        }
      }
    );

    onMounted(async () => {
      Logger.LogInfo("On mounted");
      app = getCurrentInstance();
      process.value?.setActiveProcessByName("form");

      checkSberPayRedirectUrls();
    });

    function setIsShowSaveCardElements() {
      const orderOptions = appService.getOrderOptions();
      if (
        !orderOptions?.accountId ||
        orderOptions?.terminalInfo?.IsSaveCard === SaveCard.Classic ||
        (orderOptions?.terminalInfo?.IsSaveCard === SaveCard.New &&
          !(
            orderOptions.subscription?.recurrentInterval !== null &&
            orderOptions.subscription?.period !== null
          ))
      ) {
        return;
      }

      if (
        orderOptions?.terminalInfo?.IsSaveCard === SaveCard.Optional &&
        !(
          orderOptions.subscription?.recurrentInterval !== null &&
          orderOptions.subscription?.period !== null
        )
      ) {
        appService.orderFormOptions.value.saveCard = false;
        shouldShowSaveCard.value = true;
        return;
      }

      shouldShowForceSaveHint.value = true;
    }

    function statistPayEvent(method: PaymentMethodsStatist, bank?: string) {
      monitoring?.sendStatistEvent("events.pay", {
        method: method,
        publicId: appService?.getOrderOptions().terminalPublicId,
        bank,
        cardDataCheckbox: shouldShowSaveCard.value
          ? (appService.orderFormOptions.value?.saveCard as boolean)
          : false,
        floctoryIsOn:
          appService?.getOrderOptions().terminalInfo
            ?.DisplayAdvertiseBannerOnWidget,
      } as any);
    }

    function init() {
      Logger.LogInfo("Before init");
      const availableCashMethods =
        props.order?.terminalInfo?.ExternalPaymentMethods.find(
          (method) => method.Type === ExternalPaymentMethods.Cash
        )?.CashMethods;

      const sortedCashMethods = [
        ...[CashMethods.Oxxo, CashMethods.CStores, CashMethods.Pharmacy].filter(
          (method) => availableCashMethods?.includes(method)
        ),
        ...[CashMethods.Oxxo, CashMethods.CStores, CashMethods.Pharmacy].filter(
          (method) => !availableCashMethods?.includes(method)
        ),
      ];

      cashOxxo.position = sortedCashMethods.indexOf(CashMethods.Oxxo);
      cashOxxo.isAvailable = !!availableCashMethods?.includes(CashMethods.Oxxo);

      cashCStores.position = sortedCashMethods.indexOf(CashMethods.CStores);
      cashCStores.isAvailable = !!availableCashMethods?.includes(
        CashMethods.CStores
      );

      cashPharmacy.position = sortedCashMethods.indexOf(CashMethods.Pharmacy);
      cashPharmacy.isAvailable = !!availableCashMethods?.includes(
        CashMethods.Pharmacy
      );

      setIsShowSaveCardElements();
      offerLink.value = props.order?.linkToOffer;
      privacyPoliceLink.value = runtimeConfiguration.privacyPolice;
      agreementLink.value = props.order?.terminalInfo?.AgreementPath;
      const currentLanguage = props.order?.cultureName || "en-EN";

      staticAgreementLink.value =
        currentLanguage && runtimeConfiguration.agreementUrl
          ? runtimeConfiguration.agreementUrl[`${currentLanguage}`]
          : null;
      staticOfferLink.value =
        currentLanguage && runtimeConfiguration.offerUrl
          ? runtimeConfiguration.offerUrl[`${currentLanguage}`]
          : null;

      showEmailField.value = !!props.order?.email;
      appService.email.value = props.order?.email;
      amount.value = props.order?.amount;
      currency.value = props.order?.currency;
      currencySymbol.value = Utils.getCurrencySymbol(currency.value);
      locale.value = currentLanguage;
      isCvvRequired.value = !!props.order?.isCvvRequired;
      skipExpiryValidation.value = !!props.order?.skipExpiryValidation;
      Logger.LogInfo("After init");
    }

    function checkPaySystems() {
      Logger.LogInfo("checkPaySystems");
      availablePayCount.value = 1;

      appService
        ?.isYandexPayAvailable()
        .then((result) => {
          Logger.LogInfo("yandex result", result);
          Logger.LogInfo("YandexPay result", result);
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.YandexPay
            )
          ) {
            yandexPay.isAvailable = result;
            setAvailablePayCount(yandexPay.isAvailable);
            if (yandexPay.isAvailable && !yandexPay.isMounted) {
              nextTick(() => {
                Logger.LogInfo("create yandex");
                Logger.LogInfo("create yandex");
                yandexPay.isMounted = true;
                createYandexPayButton();
              });
            }
          }
        })
        .catch(() => {
          yandexPay.isAvailable = false;
        });

      appService
        ?.isApplePayAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.ApplePay
            )
          ) {
            Logger.LogInfo("Yandex pay then", result);
            applePay.isAvailable = result;
            setAvailablePayCount(applePay.isAvailable);
          }
        })
        .catch(() => {
          applePay.isAvailable = false;
          Logger.LogInfo("Yandex pay catch");
        });

      appService
        ?.isGooglePayAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.GooglePay
            )
          ) {
            googlePay.isAvailable = result;
            setAvailablePayCount(googlePay.isAvailable);
          }
        })
        .catch(() => {
          googlePay.isAvailable = false;
        });

      appService
        ?.isTinkoffPayAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.TinkoffPay
            )
          ) {
            tinkoffPay.isAvailable = result;
            setAvailablePayCount(tinkoffPay.isAvailable);
          }
        })
        .catch(() => {
          tinkoffPay.isAvailable = false;
        });

      appService
        ?.isSberPayAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.SberPay
            )
          ) {
            sberPay.isAvailable = result;
            setAvailablePayCount(sberPay.isAvailable);
          }
        })
        .catch(() => {
          sberPay.isAvailable = false;
        });

      appService
        ?.isSpeiAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.Spei
            )
          ) {
            spei.isAvailable = result;
            setAvailablePayCount(spei.isAvailable);
          }
        })
        .catch(() => {
          spei.isAvailable = false;
        });

      appService
        ?.isCashAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.Cash
            )
          ) {
            cash.isAvailable = result;
            setAvailablePayCount(cash.isAvailable);
          }
        })
        .catch(() => {
          cash.isAvailable = false;
        });

      appService
        ?.isInstallmentCardMxAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.CardInstallmentMX
            )
          ) {
            cardInstallment.isAvailable = result;
            setAvailablePayCount(cardInstallment.isAvailable);
          }
        })
        .catch(() => {
          cardInstallment.isAvailable = false;
        });

      appService
        ?.isDolyameAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.TcsBnplDolyame
            )
          ) {
            dolyame.isAvailable = result;
            setAvailablePayCount(dolyame.isAvailable);
          }
        })
        .catch(() => {
          dolyame.isAvailable = false;
        });

      appService
        ?.isTinkoffInstallmentAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.TinkoffInstallmentPay
            )
          ) {
            tinkoffInstallment.isAvailable = result;
            setAvailablePayCount(tinkoffInstallment.isAvailable);
          }
        })
        .catch(() => {
          tinkoffInstallment.isAvailable = false;
        });

      appService
        ?.isTinkoffCreditAvailable(amount.value)
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.Loan
            )
          ) {
            tinkoffCredit.isAvailable = result;
            setAvailablePayCount(tinkoffCredit.isAvailable);
          }
        })
        .catch(() => {
          tinkoffCredit.isAvailable = false;
        });

      appService
        ?.isSBPAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.SbpPay
            )
          ) {
            sbp.isAvailable = result;
            setAvailablePayCount(sbp.isAvailable);
          }
        })
        .catch(() => {
          sbp.isAvailable = false;
        });

      appService
        ?.isSomAvailable()
        .then((result) => {
          if (
            !props.isRenewalOfSubscription ||
            runtimeConfiguration.renewalSubscriptionPaymentMethods?.includes(
              ExternalPaymentMethods.Som
            )
          ) {
            som.isAvailable = result;
            setAvailablePayCount(som.isAvailable);
          }
        })
        .catch(() => {
          som.isAvailable = false;
        });
    }

    function setAvailablePayCount(isAvailable: boolean) {
      availablePayCount.value = isAvailable
        ? availablePayCount.value + 1
        : availablePayCount.value;
    }

    async function createYandexPayButton() {
      Logger.LogInfo("create 1");
      if (!yandexPayButton.value) {
        return;
      }

      Logger.LogInfo("create 2");

      const sessionData = {
        countryCode: "RU",
        amount: appService?.getOrderOptions().amount,
        currencyCode: appService?.getOrderOptions().currency,
        orderNumber: appService?.getOrderOptions().orderNumber,
        publicId: appService?.getOrderOptions().terminalPublicId,
      };

      Logger.LogInfo("PRE MOUNT 1");

      Logger.LogInfo("pre amount", sessionData);

      await YandexPayApi.mountButton(yandexPayButton.value, sessionData);
    }

    function checkPayLength(withError = false) {
      Logger.LogInfo("checkPayLength");
      Promise.allSettled([
        appService?.isYandexPayAvailable(),
        appService?.isApplePayAvailable(),
        appService?.isGooglePayAvailable(),
        appService?.isTinkoffInstallmentAvailable(),
        appService?.isTinkoffCreditAvailable(amount.value),
        appService?.isSBPAvailable(),
        appService?.isTinkoffPayAvailable(),
        appService?.isSpeiAvailable(),
        appService?.isCashAvailable(),
        appService?.isInstallmentCardMxAvailable(),
        appService?.isSomAvailable(),
        appService?.isDolyameAvailable(),
      ])
        .then((result) => {
          Logger.LogInfo("Then check length before", result);
          const everyPayIsNotAvailable = result
            .filter((item) => item.status === "fulfilled")
            .every((item) => item.status === "fulfilled" && !item.value);

          if (everyPayIsNotAvailable) {
            if (withError) {
              process.value?.setMultipleByNames(["form", "result"]);
            }
            onChangePaymentMethod(PaymentMethodEnum.Card, withError, false);
            showMoreMethodsButton.value = false;
          }
          Logger.LogInfo("Then check length after");
          loader?.hideLoader();
        })
        .catch((error) => {
          loader?.hideLoader();
          Logger.LogError("error check pay length", error);
        })
        .finally(() => {
          loader?.hideLoader();
          Logger.LogInfo("Finally check length");
        });
    }

    function showCheckboxesCardPayment() {
      return (
        !yandexPay.isAvailable &&
        !applePay.isAvailable &&
        !googlePay.isAvailable &&
        !tinkoffInstallment.isAvailable &&
        !tinkoffCredit.isAvailable &&
        !tinkoffPay.isAvailable &&
        !spei.isAvailable &&
        !cash.isAvailable &&
        !cardInstallment.isAvailable &&
        !som.isAvailable &&
        !dolyame.isAvailable &&
        !sberPay.isAvailable
      );
    }

    function resetInstallmentFormAndCardData() {
      formInstallmentMX.isBankDataAvailable = null;
      formInstallmentMX.period = null;
      formInstallmentMX.cardData = null;
      formInstallmentMX.binInfo = null;
      formInstallmentMX.isCardDataValid = null;

      if (isInstallmentCard.value) {
        cardDataService.cardData = {
          cardNumber: "",
          cardExpiration: "",
          cardSecretCode: "",
        };
      }
    }

    async function onChangePaymentMethod(
      selectedPaymentMethod: PaymentMethodEnum | null,
      withError = false,
      needEmitOnChange = true
    ) {
      if (selectedPaymentMethod == null) {
        resetInstallmentFormAndCardData();
        isInstallmentCard.value = false;
      }
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayDifferent);
      if (
        state.paymentMethod === PaymentMethodEnum.Cash &&
        selectedPaymentMethod === null
      ) {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.ChangeMethod, {
          method: AmplitudeEvents.PayCash,
        });
      }

      if (!withError && needEmitOnChange) {
        emit("onChange");
      }
      if (showEmailField.value && !appService.email.value) {
        return;
      }

      state.paymentMethod = selectedPaymentMethod;
      showMoreMethodsButton.value =
        state.paymentMethod === PaymentMethodEnum.Card;

      if (selectedPaymentMethod && !withError) {
        process.value?.setActiveProcessByName("payment");
      } else if (selectedPaymentMethod && withError) {
        process.value?.setMultipleByNames(["result", "payment"]);
      } else {
        process.value?.setActiveProcessByName("form");
      }

      if (selectedPaymentMethod === PaymentMethodEnum.YandexPay && appService) {
        Logger.LogInfo("pay yandex");
      } else if (
        yandexPay.isAvailable ||
        applePay.isAvailable ||
        googlePay.isAvailable ||
        tinkoffInstallment.isAvailable ||
        tinkoffCredit.isAvailable ||
        spei.isAvailable ||
        cash.isAvailable ||
        cardInstallment.isAvailable ||
        som.isAvailable
      ) {
        Logger.LogInfo("check pay");
        checkPaySystems();
      }
    }

    async function onSuccessCard(callbackResult: PayResponse) {
      if (isInstallmentCard.value) {
        resetInstallmentFormAndCardData();
      }

      if (!callbackResult?.Model) {
        state.success = false;
      }

      if (callbackResult?.Model?.PaReq) {
        await on3ds(callbackResult);
      } else {
        process.value?.setActiveProcessByName("form");
        state.success = !!callbackResult?.Success;

        if (!callbackResult?.Success && callbackResult?.Model?.ReasonCode) {
          state.reasonCode = callbackResult?.Model?.ReasonCode;
        }

        appService?.getOrderOptions()?.update({
          paid: state.success,
          transactionId: callbackResult?.Model?.TransactionId,
          accountId: (callbackResult?.Model as any)?.AccountId || "",
        });

        showMoreMethodsButton.value =
          state.paymentMethod === PaymentMethodEnum.Card;

        nextTick(() => {
          if (state.paymentMethod && state.paymentMethod === "card") {
            process.value?.setMultipleByNames(["payment", "result"]);
          } else {
            process.value?.setMultipleByNames(["form", "result"]);
          }
        });

        showMoreMethodsButton.value =
          state.paymentMethod === PaymentMethodEnum.Card;

        checkPaySystems();
        checkPayLength(!state.success);
        emit(
          state.success ? "onSuccess" : "onFail",
          state.paymentMethod,
          callbackResult?.Model?.TransactionId
        );
      }

      cardPay.isLoading = false;
      yandexPay.isLoading = false;
      applePay.isLoading = false;
      googlePay.isLoading = false;
      tinkoffInstallment.isLoading = false;
      loader?.hideLoader();
    }

    async function yandexPayError(reason: any) {
      await onSuccessCard(reason);
      await reinitializeYandexPaySession();
    }

    async function reinitializeYandexPaySession() {
      Logger.LogInfo("re init yandex");
      await YandexPayApi.reinitializeYandexPaySession(yandexPayButton.value);
      const sessionData = {
        countryCode: "RU",
        amount: appService?.getOrderOptions().amount,
        currencyCode: appService?.getOrderOptions().currency,
        orderNumber: appService?.getOrderOptions().orderNumber,
        publicId: appService?.getOrderOptions().terminalPublicId,
      };

      await YandexPayApi.mountButton(yandexPayButton.value, sessionData);
    }

    async function payByYandexPay(event: any) {
      statistPayEvent(PaymentMethodsStatist.YandexPay);

      resetState();
      state.paymentMethod = PaymentMethodEnum.YandexPay;

      loader?.showLoader();
      yandexPay.isLoading = true;

      const errorPayResponse: PayResponse = {
        Success: false,
      } as PayResponse;

      if (event || event?.token) {
        const cryptogram = atob(event.token);
        const cryptogramWithBrowserInfo = JSON.stringify({
          ...JSON.parse(cryptogram),
          BrowserInfoBase64: getBrowserInfo(),
        });

        const orderNumber = appService?.getOrderOptions()?.publicOrderId;
        const email =
          appService?.getOrderOptions()?.email || appService.email.value;

        const isOrder = appService?.getOrderOptions()?.isOrder;

        appService?.payment
          ?.pay(isOrder)(
            orderNumber,
            cryptogramWithBrowserInfo,
            email,
            appService?.orderFormOptions?.value?.saveCard
          )
          .then(async (callbackResult: PayResponse) => {
            await onSuccessCard(callbackResult);
          })
          .catch(async () => {
            await yandexPayError(errorPayResponse);
          })
          .finally(() => {
            yandexPay.isLoading = false;
            loader?.hideLoader();
          });
      }
    }

    function resetState() {
      state.success = false;
      state.paymentMethod = null;
      state.reasonCode = null;
    }

    async function payByApplePay() {
      if (showEmailField.value && !appService.email.value) {
        return;
      }
      state.reasonCode = null;
      loader?.showLoader();
      applePay.isLoading = true;
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayApplePay);

      statistPayEvent(PaymentMethodsStatist.ApplePay);

      const errorPayResponse: PayResponse = {
        Success: false,
      } as PayResponse;

      try {
        let callbackResult: PayResponse | undefined =
          await appService?.payment?.payByApplePay(
            {
              amount: appService?.getOrderOptions()?.amount,
              orderNumber: appService?.getOrderOptions()?.publicOrderId,
              publicId: appService?.getOrderOptions()?.publicId,
              currency: appService?.getOrderOptions()?.currency,
              isOrder: appService?.getOrderOptions()?.isOrder,
              requireEmail: showEmailField.value,
            },
            appService.orderFormOptions?.value?.saveCard
          );

        loader?.hideLoader();
        applePay.isLoading = false;
        state.paymentMethod = PaymentMethodEnum.ApplePay;
        if (callbackResult) {
          await onSuccessCard(callbackResult);
        } else {
          await onSuccessCard(errorPayResponse);
          loader?.hideLoader();
          applePay.isLoading = false;
        }
      } catch (e) {
        Logger.LogError("catch", e);
        state.paymentMethod = PaymentMethodEnum.ApplePay;
        await onSuccessCard(errorPayResponse);
      }
    }

    async function payByGooglePay() {
      if (showEmailField.value && !appService.email.value) {
        return;
      }
      state.reasonCode = null;
      loader?.showLoader();
      googlePay.isLoading = true;

      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayGooglePay);

      statistPayEvent(PaymentMethodsStatist.GooglePay);

      const errorPayResponse: PayResponse = {
        Success: false,
      } as PayResponse;

      const allowedPaymentMethods =
        props.order?.terminalInfo.ExternalPaymentMethods.find(
          (pm) => pm.Type == ExternalPaymentMethods.GooglePay
        )!.AllowedPaymentMethods || [];

      try {
        let callbackResult: PayResponse | undefined =
          await appService?.payment?.payByGooglePay(
            {
              amount: appService?.getOrderOptions()?.amount,
              orderNumber: appService?.getOrderOptions()?.publicOrderId,
              publicId: appService?.getOrderOptions()?.publicId,
              currency: appService?.getOrderOptions()?.currency,
              isOrder: appService?.getOrderOptions()?.isOrder,
              requireEmail: showEmailField.value,
            },
            allowedPaymentMethods as string[],
            appService.orderFormOptions?.value?.saveCard
          );

        loader?.hideLoader();
        googlePay.isLoading = false;
        state.paymentMethod = PaymentMethodEnum.GooglePay;
        if (callbackResult) {
          await onSuccessCard(callbackResult);
        } else {
          await onSuccessCard(errorPayResponse);
          loader?.hideLoader();
          googlePay.isLoading = false;
        }
      } catch (e) {
        Logger.LogError("catch", e);
        state.paymentMethod = PaymentMethodEnum.GooglePay;
        await onSuccessCard(errorPayResponse);
      }
    }

    async function goToCardInstallment() {
      isInstallmentCard.value = true;
      await onChangePaymentMethod(PaymentMethodEnum.Card);
    }

    async function payBySpei() {
      statistPayEvent(PaymentMethodsStatist.SPEI);

      monitoring?.sendAmplitudeEvent(AmplitudeEvents.Pay, {
        method: AmplitudeEvents.PaySpei,
      });
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PaySpei);
      spei.isLoading = true;

      if (sessionStorageService?.get("ALT_PAY_RESPONSE")) {
        altPayResponseData.value = sessionStorageService.get(
          "ALT_PAY_RESPONSE"
        ) as CreateAltPayOrderResponse;
      } else {
        altPayResponseData.value = (await appService
          .getPayment()
          .payByAltPay(appService?.getOrderOptions(), AltPayType.Spei)
          .catch((e) => {
            Logger.LogError(e);
            appService.setSpeiAlertType("error");
            emit("onFail", PaymentMethodEnum.Spei);
          })) as CreateAltPayOrderResponse;
      }

      terminalName.value = appService?.getOrderOptions().terminalName;
      spei.isLoading = false;
      if (
        altPayResponseData.value?.Success &&
        altPayResponseData.value?.Model
      ) {
        sessionStorageService?.set(
          "ALT_PAY_RESPONSE",
          altPayResponseData.value
        );
        isSpei.value = true;
      } else {
        if (altPayResponseData.value?.ErrorCode) {
          state.success = false;
          state.paymentMethod = PaymentMethodEnum.Spei;
          state.reasonCode = altPayResponseData.value?.ErrorCode;
          emit("onFail", PaymentMethodEnum.Spei);
          process.value?.setMultipleByNames(["form", "result"]);
        } else {
          appService.setSpeiAlertType("error");
          emit("onFail", PaymentMethodEnum.Spei);
        }
      }
    }

    async function selectCashPayment(fromBarcode = false) {
      if (fromBarcode) {
        let method = AmplitudeEvents.PayCashOxxo;

        if (selectedCashMethod.value === CashMethods.CStores) {
          method = AmplitudeEvents.PayCashCStore;
        }

        if (selectedCashMethod.value === CashMethods.Pharmacy) {
          method = AmplitudeEvents.PayCashPharmacy;
        }

        monitoring?.sendAmplitudeEvent(AmplitudeEvents.ChangeMethod, {
          method,
        });
      } else {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.Pay, {
          method: AmplitudeEvents.PayCash,
        });
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayCash);
      }
      state.paymentMethod = PaymentMethodEnum.Cash;
      showMoreMethodsButton.value = true;
      cashForm.email = props.order?.email || appService.email.value || null;
      selectedCashMethod.value = undefined;
      process.value?.setActiveProcessByName("cash_payment");
    }

    async function payByCash(cashMethod: CashMethods) {
      let altPayType = AltPayType.CashCStores;

      if (cashMethod === CashMethods.CStores) {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayCashCStore);
        cashCStores.isLoading = true;
      }

      if (cashMethod === CashMethods.Oxxo) {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayCashOxxo);
        cashOxxo.isLoading = true;
        altPayType = AltPayType.CashOxxo;
      }

      if (cashMethod === CashMethods.Pharmacy) {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayCashPharmacy);
        cashPharmacy.isLoading = true;
        altPayType = AltPayType.CashPharmacy;
      }

      showMoreMethodsButton.value = false;
      barcodeCashLinkOpened.value = false;

      let altPayResponse: CreateAltPayResponse | undefined;

      const processingCashError = () => {
        cashOxxo.isLoading = false;
        cashCStores.isLoading = false;
        cashPharmacy.isLoading = false;
        state.success = false;
        const reasonCode = altPayResponse?.ErrorCode;
        if (reasonCode) {
          state.reasonCode = reasonCode;
        }
        state.paymentMethod = PaymentMethodEnum.Cash;
        showMoreMethodsButton.value = false;
        emit("onFail", state.paymentMethod);
        process.value?.setMultipleByNames(["form", "result"]);
      };

      try {
        let cashRequest: CreateAltPayOrderRequest = {
          publicOrderId: appService?.getOrderOptions()?.publicOrderId,
          email: appService.email.value || appService?.getOrderOptions()?.email,
          altPayType,
          payer: {
            firstName: cashForm.name,
            email: cashForm.email,
          },
          SuccessRedirectUrl: appService?.getOrderOptions()?.successRedirectUrl,
          FailRedirectUrl: appService?.getOrderOptions()?.failRedirectUrl,
        };

        altPayResponse = await altPayApi?.createAltPayTransaction(cashRequest);
        cashOxxo.isLoading = false;
        cashCStores.isLoading = false;
        cashPharmacy.isLoading = false;

        if (
          !altPayResponse?.Model?.ExtensionData?.Link ||
          !altPayResponse?.Success
        ) {
          processingCashError();
          return;
        }

        selectedCashMethod.value = cashMethod;
        barcodeCashLink.value = altPayResponse?.Model?.ExtensionData?.Link;
        process.value?.setActiveProcessByName("cash_barcode");
      } catch (err) {
        processingCashError();
      }
    }

    function onOpenBarcodeLink() {
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayCashOpenBarcode);
      barcodeCashLinkOpened.value = true;
    }

    function onSuccessSpei() {
      goToPaymentProcess();
      emit("onSuccess", PaymentMethodEnum.Spei);
    }

    function goToPaymentProcess() {
      isSpei.value = false;
    }

    async function payBySberPay() {
      sberPay.isLoading = true;

      const deviceDetector = new DeviceDetector();
      if (!deviceDetector.isMobile) {
        const modalResult = await modal
          ?.openModal({
            component: Qr,
            model: { mode: QrMode.SberPay, email: appService.email.value },
          })
          .then((result: any) => {
            if (result?.status === TransactionResultStatus.Success) {
              state.success = true;
              state.paymentMethod = PaymentMethodEnum.SberPay;
              emit("onSuccess", PaymentMethodEnum.SberPay);
              process.value?.setMultipleByNames(["payment", "result"]);
            }

            if (result?.status === TransactionResultStatus.Fail) {
              state.success = false;
              if (result?.errorCode) {
                state.reasonCode = result?.errorCode;
              }
              state.paymentMethod = PaymentMethodEnum.SberPay;
              emit("onFail", PaymentMethodEnum.SberPay);
              process.value?.setMultipleByNames(["form", "result"]);
            }

            sberPay.isLoading = false;
          });

        return;
      }

      const linkResponse = await sberPayApi?.createSberPayQrLink({
        publicOrderId: appService?.getOrderOptions().publicOrderId,
        email: appService!.email.value,
        saveCard: appService.orderFormOptions.value.saveCard,
      });

      if (!linkResponse?.Success || !linkResponse?.Model?.QrUrl) {
        if (linkResponse?.ErrorCode) {
          state.reasonCode = linkResponse?.ErrorCode;
        }

        Logger.LogError("QrUrl is not valid");
        sberPay.isLoading = false;
        state.success = false;
        state.paymentMethod = PaymentMethodEnum.SberPay;
        emit("onFail", PaymentMethodEnum.SberPay);
        process.value?.setMultipleByNames(["form", "result"]);

        return;
      }

      Logger.LogInfo("Link response QR", linkResponse?.Model?.QrUrl);

      const budgetIosUrl = new URL(linkResponse?.Model?.QrUrl);
      budgetIosUrl.protocol = "ios-app-smartonline:";
      budgetIosUrl.href = budgetIosUrl.href.replace(
        "budgetonline-ios://",
        "budgetonline-ios://sbolpay/"
      );

      Logger.LogInfo("Sberpay protocol URL href", budgetIosUrl);

      const iosAppUrl = new URL(linkResponse?.Model?.QrUrl);
      iosAppUrl.protocol = "ios-app-smartonline:";
      iosAppUrl.href = iosAppUrl.href.replace(
        "ios-app-smartonline://",
        "ios-app-smartonline://sbolpay/"
      );

      Logger.LogInfo("Sberpay protocol URL href", iosAppUrl);

      const sberPayUrl = new URL(linkResponse?.Model?.QrUrl as string);
      sberPayUrl.protocol = "sberpay:";

      Logger.LogInfo("Sberpay protocol URL href", sberPayUrl);

      const sbolPayUrl = new URL(linkResponse?.Model?.QrUrl as string);
      sbolPayUrl.protocol = "sbolpay:";

      Logger.LogInfo("Sbolpay protocol URL href", sbolPayUrl);

      const tripUrl = new URL(linkResponse?.Model?.QrUrl as string);
      tripUrl.protocol = "btripsexpenses://sbolpay/";
      tripUrl.href = tripUrl.href.replace(
        "btripsexpenses://",
        "btripsexpenses://sbolpay/"
      );

      Logger.LogInfo("Btripsexpenses protocol URL href", tripUrl);

      if (deviceDetector.isIOs) {
        if (isSafari()) {
          const urls = [
            tripUrl.href,
            budgetIosUrl.href,
            iosAppUrl.href,
            sbolPayUrl.href,
            sberPayUrl.href,
          ];

          const newUrlWithParams = new URL(window.location.href);

          if (linkResponse?.Model?.TransactionId) {
            newUrlWithParams.searchParams.append(
              "transactionId",
              (linkResponse?.Model?.TransactionId as number).toString()
            );
          } else {
            Logger.LogError("TransactionId is not valid");
          }

          newUrlWithParams.searchParams.append(
            "linksList",
            JSON.stringify(urls)
          );

          window.location.href = newUrlWithParams.href;
          return;
        } else {
          window.location.href = tripUrl.href;
          window.location.href = budgetIosUrl.href;
          window.location.href = iosAppUrl.href;
          window.location.href = sbolPayUrl.href;
          window.location.href = sberPayUrl.href;
        }
      } else {
        window.location.href = sberPayUrl.href;
      }

      startSberPayMobileWaitingFlow(
        linkResponse?.Model?.TransactionId as number
      );
    }

    function startSberPayMobileWaitingFlow(transactionId: number) {
      const waitSberPayTask = new IterableTask(
        () =>
          (sberPayApi as SberPayApi).waitSberPayStatus({
            terminalPublicId: appService.getOrderOptions()?.terminalPublicId,
            transactionId,
          }),
        (result) => result === TransactionResultStatus.Wait
      );

      const visibleListener = new PageVisibility();
      visibleListener.subscribe((visible) => {
        if (visible) {
          Logger.LogInfo("Page visible");
          modalTimer.resume();
        } else {
          Logger.LogInfo("Page invisible");
          modalTimer.pause();
        }
      });

      function reloadStatus() {
        return (sberPayApi as SberPayApi)
          .waitSberPayStatus({
            terminalPublicId: appService.getOrderOptions().publicId,
            transactionId,
          })
          .then((result) => {
            modal?.closeModal();
            if (result === TransactionResultStatus.Success) {
              state.success = true;
              state.paymentMethod = PaymentMethodEnum.SberPay;
              emit("onSuccess", PaymentMethodEnum.SberPay);
              process.value?.setMultipleByNames(["payment", "result"]);
            }

            if (result === TransactionResultStatus.Fail) {
              state.success = false;
              state.paymentMethod = PaymentMethodEnum.SberPay;
              emit("onFail", PaymentMethodEnum.SberPay);
              process.value?.setMultipleByNames(["form", "result"]);
            }
          });
      }

      const modalTimer = new Timer(() => {
        visibleListener.destroy();
        modal
          ?.openModal<any, any, any>({
            component: WaitBottomSheet,
            model: {
              icon: SberPayLogoSmall,
              reloadStatus,
            },
            options: {
              type: ModalType.SlideFromBottom,
              fullscreenMode: false,
              hideClose: true,
              noPadding: true,
            },
          })
          .then(() => {
            monitoring?.sendAmplitudeEvent(AmplitudeEvents.ChangeMethod, {
              method: QrMode.TinkoffPay,
              screen: AmplitudeEvents.WaitScreen,
            });
            Logger.LogInfo("User has cancelled SberPay waiting");
            waitSberPayTask?.terminate();
            sberPay.isLoading = false;
          });
      }, 5000);

      waitSberPayTask
        .then((result: TransactionResultStatus) => {
          visibleListener.destroy();
          modalTimer?.pause();
          modal?.closeModal();
          sberPay.isLoading = false;
          if (result === TransactionResultStatus.Success) {
            state.success = true;
            state.paymentMethod = PaymentMethodEnum.SberPay;
            emit("onSuccess", PaymentMethodEnum.SberPay);
            process.value?.setMultipleByNames(["payment", "result"]);
          }

          if (result === TransactionResultStatus.Fail) {
            state.success = false;
            state.paymentMethod = PaymentMethodEnum.SberPay;
            emit("onFail", PaymentMethodEnum.SberPay);
            process.value?.setMultipleByNames(["form", "result"]);
          }
        })
        .catch((e) => {
          visibleListener.destroy();
          modalTimer?.pause();
          modal?.closeModal();
          sberPay.isLoading = false;
          Logger.LogError("WaitStatus Error", e);
        });
    }

    async function payByTinkoffPay() {
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.Pay, {
        method: AmplitudeEvents.PayTinkoffPay,
      });
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayTinkoffPay);

      statistPayEvent(PaymentMethodsStatist.TinkoffPay);

      tinkoffPay.isLoading = true;

      const deviceDetector = new DeviceDetector();
      if (!deviceDetector.isMobile) {
        const modalResult = await modal
          ?.openModal({
            component: Qr,
            model: { mode: QrMode.TinkoffPay, email: appService.email.value },
          })
          .then((result: any) => {
            if (result?.status === TransactionResultStatus.Success) {
              state.success = true;
              state.paymentMethod = PaymentMethodEnum.TinkoffPay;
              emit("onSuccess", PaymentMethodEnum.TinkoffPay);
              process.value?.setMultipleByNames(["payment", "result"]);
            }

            if (result?.status === TransactionResultStatus.Fail) {
              state.success = false;
              if (result?.errorCode) {
                state.reasonCode = result?.errorCode;
              }
              state.paymentMethod = PaymentMethodEnum.TinkoffPay;
              emit("onFail", PaymentMethodEnum.TinkoffPay);
              process.value?.setMultipleByNames(["form", "result"]);
            }

            tinkoffPay.isLoading = false;
          });

        return;
      }

      const linkResponse = await tinkoffPayApi?.createTinkoffPayQrLink({
        publicOrderId: appService?.getOrderOptions().publicOrderId,
        email: appService!.email.value,
        saveCard: appService.orderFormOptions.value.saveCard,
      });

      Logger.LogInfo("linkResponse.Model.QrUrl: ", linkResponse?.Model?.QrUrl);

      if (!linkResponse?.Success && linkResponse?.ErrorCode) {
        Logger.LogError("linkResponse error", linkResponse?.ErrorCode);
        state.reasonCode = linkResponse.ErrorCode;
        state.success = false;
        state.paymentMethod = PaymentMethodEnum.TinkoffPay;
        emit("onFail", PaymentMethodEnum.TinkoffPay);
        process.value?.setMultipleByNames(["form", "result"]);
        tinkoffPay.isLoading = false;
        return;
      }

      if (linkResponse?.Model?.QrUrl) {
        window.location.href = linkResponse?.Model?.QrUrl;
      }

      const waitTinkoffPayTask = new IterableTask(
        () =>
          (tinkoffPayApi as TinkoffPayApi).waitTinkoffPayStatus({
            terminalPublicId: appService.getOrderOptions().publicId,
            transactionId: linkResponse?.Model?.TransactionId,
          }),
        (result) => result === TransactionResultStatus.Wait
      );

      const visibleListener = new PageVisibility();
      visibleListener.subscribe((visible) => {
        if (visible) {
          Logger.LogInfo("Page visible");
          modalTimer.resume();
        } else {
          Logger.LogInfo("Page invisible");
          modalTimer.pause();
        }
      });

      function reloadStatus() {
        return (tinkoffPayApi as TinkoffPayApi)
          .waitTinkoffPayStatus({
            terminalPublicId: appService.getOrderOptions().publicId,
            transactionId: linkResponse?.Model?.TransactionId,
          })
          .then((result) => {
            modal?.closeModal();
            if (result === TransactionResultStatus.Success) {
              state.success = true;
              state.paymentMethod = PaymentMethodEnum.TinkoffPay;
              emit("onSuccess", PaymentMethodEnum.TinkoffPay);
              process.value?.setMultipleByNames(["payment", "result"]);
            }

            if (result === TransactionResultStatus.Fail) {
              state.success = false;
              state.paymentMethod = PaymentMethodEnum.TinkoffPay;
              emit("onFail", PaymentMethodEnum.TinkoffPay);
              process.value?.setMultipleByNames(["form", "result"]);
            }
          });
      }

      const modalTimer = new Timer(() => {
        visibleListener.destroy();
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.WaitScreen, {
          method: AmplitudeEvents.PayTinkoffPay,
        });
        modal
          ?.openModal<any, any, any>({
            component: WaitBottomSheet,
            model: {
              icon: TinkoffPayIconBig,
              reloadStatus,
            },
            options: {
              type: ModalType.SlideFromBottom,
              fullscreenMode: false,
              hideClose: true,
              noPadding: true,
            },
          })
          .then(() => {
            monitoring?.sendAmplitudeEvent(AmplitudeEvents.ChangeMethod, {
              method: QrMode.TinkoffPay,
              screen: AmplitudeEvents.WaitScreen,
            });
            Logger.LogInfo("User has cancelled TinkoffPay waiting");
            waitTinkoffPayTask?.terminate();
            tinkoffPay.isLoading = false;
          });
      }, 5000);

      waitTinkoffPayTask
        .then((result: TransactionResultStatus) => {
          visibleListener.destroy();
          modalTimer?.pause();
          modal?.closeModal();
          tinkoffPay.isLoading = false;
          if (result === TransactionResultStatus.Success) {
            state.success = true;
            state.paymentMethod = PaymentMethodEnum.TinkoffPay;
            emit("onSuccess", PaymentMethodEnum.TinkoffPay);
            process.value?.setMultipleByNames(["payment", "result"]);
          }

          if (result === TransactionResultStatus.Fail) {
            state.success = false;
            state.paymentMethod = PaymentMethodEnum.TinkoffPay;
            emit("onFail", PaymentMethodEnum.TinkoffPay);
            process.value?.setMultipleByNames(["form", "result"]);
          }
        })
        .catch((e) => {
          visibleListener.destroy();
          modalTimer?.pause();
          modal?.closeModal();
          tinkoffPay.isLoading = false;
          Logger.LogError("WaitStatus Error", e);
        });
    }

    async function payByTcs(promoCode: string, isCredit?: boolean) {
      if (!runtimeConfiguration?.isTinkoffTcsNewFlow) {
        await payByTinkoffInstallmentOld(promoCode, isCredit);
      } else {
        await payByTinkoffInstallmentNew(promoCode, isCredit);
      }
    }

    async function startTinkoffInstallmentProcess() {
      statistPayEvent(PaymentMethodsStatist.TinkoffInstallmentPay);

      let basePromoCode = "installment_0_0_6_6,5";
      let promoCodes: string[] | undefined;

      let tinkoffInstallmentData = appService
        .getOrderOptions()
        .terminalInfo?.ExternalPaymentMethods.find(
          (data) => data.Type === ExternalPaymentMethods.TinkoffInstallmentPay
        );

      if (
        tinkoffInstallmentData &&
        tinkoffInstallmentData.TcsCreditPeriodSettings?.IsEnabled
      ) {
        promoCodes = tinkoffInstallmentData?.TcsCreditPeriodSettings?.Periods;
      }

      if (
        !promoCodes ||
        !tinkoffInstallmentData?.TcsCreditPeriodSettings?.IsEnabled
      ) {
        await payByTcs(basePromoCode);
        return;
      }

      if (promoCodes.length === 1) {
        await payByTcs(promoCodes[0]);
        return;
      }

      let title = "";
      if (app) {
        const vueLang = app?.appContext.config.globalProperties;
        title = appService.getOrderOptions()?.terminalInfo?.IsCharity
          ? vueLang.$t("app.header.title.charity")
          : vueLang.$t("app.header.title.order");
      }

      let tinkoffInstallmentPropsData: TinkoffInstallmentData = {
        email: appService.email.value,
        promoCodes,
        brand: appService.getOrderOptions()?.terminalLogoUrl,
        amount: appService.getOrderOptions()?.amount,
        title,
        currencySign: appService.getOrderOptions()?.currencySign,
      };

      const modalResult = await modal
        ?.openModal({
          component: InstallmentModal,
          model: { ...tinkoffInstallmentPropsData },
        })
        .then((promoCodeResult) => {
          if (!promoCodeResult) {
            return;
          }

          basePromoCode = promoCodeResult as string;
          payByTcs(basePromoCode);
        });
    }

    async function payByTinkoffCredit() {
      statistPayEvent(PaymentMethodsStatist.TinkoffCredit);

      await payByTcs("default", true);
    }

    async function payByTinkoffInstallmentNew(
      promoCode: string,
      isCredit?: boolean
    ) {
      if (showEmailField.value && !appService.email.value) {
        return;
      }

      if (isCredit) {
        tinkoffCredit.isLoading = true;
      } else {
        tinkoffInstallment.isLoading = true;
      }

      const deviceDetector = new DeviceDetector();

      let checkIsInstallmentTcsWindowClosed: number;

      let waitInstallmentTimer: Timer | null = null;

      try {
        const tcsCanceled = () => {
          Logger.LogInfo("User has cancelled Tcs waiting");
          tinkoffCredit.isLoading = false;
          tinkoffInstallment.isLoading = false;

          if (waitTcsTask) {
            waitTcsTask?.terminate();
          }

          if (modal) {
            modal.closeModal();
          }

          if (checkIsInstallmentTcsWindowClosed) {
            clearInterval(checkIsInstallmentTcsWindowClosed);
          }

          if (waitInstallmentTimer) {
            waitInstallmentTimer.pause();
          }
        };

        let tcsWindow: Window | null | undefined;
        let waitTcsTask: IterableTask<any>;

        const tcsAltPayResponse = await altPayApi?.createAltPayTransaction({
          publicOrderId: appService?.getOrderOptions()?.publicOrderId,
          email: appService.email.value || appService?.getOrderOptions()?.email,
          altPayType: isCredit ? AltPayType.Loan : AltPayType.Installment,
          PromoCode: !isCredit ? promoCode : undefined,
          version: 1,
        });

        const isError =
          !tcsAltPayResponse?.Model?.ExtensionData?.Link ||
          !tcsAltPayResponse?.Model?.TransactionId ||
          !tcsAltPayResponse?.Success;

        if (isError) {
          tinkoffCredit.isLoading = false;
          tinkoffInstallment.isLoading = false;

          if (tcsAltPayResponse?.ErrorCode) {
            state.reasonCode = tcsAltPayResponse?.ErrorCode;
          }
          state.success = false;
          state.paymentMethod = isCredit
            ? PaymentMethodEnum.Loan
            : PaymentMethodEnum.Tinkoff;
          emit(
            "onFail",
            isCredit ? PaymentMethodEnum.Loan : PaymentMethodEnum.Tinkoff
          );
          process.value?.setMultipleByNames(["form", "result"]);
          return;
        }

        if (!isTelegram()) {
          if (isSafari()) {
            modal!
              .openModal<any, any, any>({
                component: WaitLink,
                model: {
                  link: tcsAltPayResponse?.Model?.ExtensionData?.Link,
                  baseUrl: environment.orders.baseUrl,
                  needFullscreen: true,
                  needListener: false,
                },
                options: {
                  type: ModalType.Default,
                  hideClose: true,
                  noPadding: true,
                  fullscreenMode: false,
                },
              })
              .then((result) => {
                if (result?.status === "canceled") {
                  tcsCanceled();
                }
              });
          } else {
            if (!isInstagramWebView()) {
              tcsWindow = window.open(
                tcsAltPayResponse?.Model?.ExtensionData?.Link,
                "_blank"
              );

              checkIsInstallmentTcsWindowClosed = setInterval(
                () => possibleInstallmentTcsCanceled(!!tcsWindow?.closed),
                200
              );

              const possibleInstallmentTcsCanceled = function (
                closed: boolean
              ) {
                if (closed) {
                  clearInterval(checkIsInstallmentTcsWindowClosed);
                  tcsCanceled();
                }
              };
            } else {
              window.location.href = tcsAltPayResponse?.Model?.ExtensionData
                ?.Link as string;
            }
          }
        } else {
          telegramRedirect(
            tcsAltPayResponse?.Model?.ExtensionData?.Link as string
          );
        }

        if (!isSafari()) {
          modal!
            .openModal<any, any, any>({
              component: WaitBottomSheet,
              model: {
                isSom: true,
              },
              options: {
                type: deviceDetector?.isMobileSbpSpecific
                  ? ModalType.SlideFromBottom
                  : ModalType.Default,
                hideClose: true,
                noPadding: true,
                fullscreenMode: false,
              },
            })
            .then((result) => {
              if (result?.status == "canceled") {
                Logger.LogInfo("User has cancelled Som waiting");
                tcsCanceled();
              }
            });
        }

        waitInstallmentTimer = new Timer(() => {
          waitTcsTask?.terminate();

          tinkoffCredit.isLoading = false;
          tinkoffInstallment.isLoading = false;

          if (modal) {
            modal.closeModal();
          }

          if (checkIsInstallmentTcsWindowClosed) {
            clearInterval(checkIsInstallmentTcsWindowClosed);
          }
        }, 1200000);

        waitTcsTask = new IterableTask(
          () =>
            (tinkoffPayApi as TinkoffPayApi).waitTinkoffPayStatus({
              terminalPublicId: appService.getOrderOptions().publicId,
              transactionId: tcsAltPayResponse?.Model?.TransactionId,
            }),
          (result) => result === TransactionResultStatus.Wait
        );

        waitTcsTask
          .then((result: TransactionResultStatus) => {
            modal?.closeModal();
            tinkoffCredit.isLoading = false;
            tinkoffInstallment.isLoading = false;

            if (result === TransactionResultStatus.Success) {
              state.success = true;
              state.paymentMethod = isCredit
                ? PaymentMethodEnum.Loan
                : PaymentMethodEnum.Tinkoff;
              emit(
                "onSuccess",
                isCredit ? PaymentMethodEnum.Loan : PaymentMethodEnum.Tinkoff
              );
              process.value?.setMultipleByNames(["payment", "result"]);
            }

            if (result === TransactionResultStatus.Fail) {
              state.success = false;
              state.paymentMethod = isCredit
                ? PaymentMethodEnum.Loan
                : PaymentMethodEnum.Tinkoff;
              emit(
                "onFail",
                isCredit ? PaymentMethodEnum.Loan : PaymentMethodEnum.Tinkoff
              );
              process.value?.setMultipleByNames(["form", "result"]);
            }
          })
          .catch((e) => {
            Logger.LogError("WaitStatus Error", e);
          });

        if (!isSafari()) {
          modal!
            .openModal<any, any, any>({
              component: WaitBottomSheet,
              model: {},
              options: {
                type: deviceDetector.isMobileSbpSpecific
                  ? ModalType.SlideFromBottom
                  : ModalType.Default,
                hideClose: true,
                noPadding: true,
                fullscreenMode: false,
              },
            })
            .then((result) => {
              if (result?.status == "canceled") {
                Logger.LogInfo("User has cancelled Tcs waiting");
                tcsCanceled();
              }
            });
        }
      } catch {
        await onSuccessCard({
          Success: false,
        } as PayResponse);
        loader?.hideLoader();
        tinkoffInstallment.isLoading = false;
        tinkoffCredit.isLoading = false;

        return;
      }
    }

    async function payByTinkoffInstallmentOld(
      promoCode: string,
      isCredit?: boolean
    ) {
      if (showEmailField.value && !appService.email.value) {
        return;
      }

      let transactionId;

      if (isCredit) {
        tinkoffCredit.isLoading = true;
      } else {
        tinkoffInstallment.isLoading = true;
      }

      try {
        const createTransactionResponse =
          await altPayApi?.createAltPayTransaction({
            publicOrderId: appService?.getOrderOptions()?.publicOrderId,
            email:
              appService.email.value || appService?.getOrderOptions()?.email,
            altPayType: isCredit ? AltPayType.Loan : AltPayType.Installment,
            PromoCode: !isCredit ? promoCode : undefined,
            version: 0,
          });

        if (!createTransactionResponse?.Success) {
          const failData = {
            Success: false,
          } as PayResponse;

          if (createTransactionResponse?.ErrorCode) {
            failData.Model = {
              ReasonCode: createTransactionResponse.ErrorCode,
            };
          }

          await onSuccessCard(failData as PayResponse);
          loader?.hideLoader();
          tinkoffInstallment.isLoading = false;
          tinkoffCredit.isLoading = false;

          return;
        }

        transactionId =
          createTransactionResponse?.Model?.TransactionId.toString();
      } catch {
        await onSuccessCard({
          Success: false,
        } as PayResponse);
        loader?.hideLoader();
        tinkoffInstallment.isLoading = false;
        tinkoffCredit.isLoading = false;

        return;
      } finally {
        loader?.hideLoader();
        tinkoffInstallment.isLoading = false;
        tinkoffCredit.isLoading = false;
      }

      const errorPayResponse: PayResponse = {
        Success: false,
      } as PayResponse;

      try {
        let callbackResult: PayResponse | undefined =
          await appService?.payment?.payByTinkoffInstallment(
            {
              ...appService?.getOrderOptions(),
              email:
                appService.email.value || appService?.getOrderOptions()?.email,
            } as OrderOptions,
            promoCode,
            transactionId,
            isCredit
          );

        loader?.hideLoader();
        applePay.isLoading = false;
        state.paymentMethod = isCredit
          ? PaymentMethodEnum.Loan
          : PaymentMethodEnum.Tinkoff;
        if (callbackResult) {
          await onSuccessCard(callbackResult as PayResponse);
        } else {
          await onSuccessCard(errorPayResponse);
          loader?.hideLoader();
          tinkoffInstallment.isLoading = false;
          tinkoffCredit.isLoading = false;
        }
      } catch (e) {
        Logger.LogError("catch", e);
        state.paymentMethod = isCredit
          ? PaymentMethodEnum.Loan
          : PaymentMethodEnum.Tinkoff;
        await onSuccessCard(errorPayResponse);
      }
    }

    async function payByDolyame() {
      statistPayEvent(PaymentMethodsStatist.BNPL);

      monitoring?.sendAmplitudeEvent(AmplitudeEvents.Pay, {
        method: AmplitudeEvents.PayDolyame,
      });
      dolyame.isLoading = true;

      try {
        const dolyameCanceled = () => {
          Logger.LogInfo("User has cancelled dolyame waiting");
          dolyame.isLoading = false;
        };

        let dolyameRequest: CreateAltPayOrderRequest = {
          publicOrderId: appService?.getOrderOptions()?.publicOrderId,
          email: appService.email.value || appService?.getOrderOptions()?.email,
          altPayType: AltPayType.TcsBnplDolyame,
        };

        if (!isInstagramWebView()) {
          dolyameRequest.SuccessRedirectUrl = `${environment.orders.baseUrl}${environment.orders.resultPage}?DolyameSuccess=true`;
          dolyameRequest.FailRedirectUrl = `${environment.orders.baseUrl}${environment.orders.resultPage}?DolyameSuccess=false`;
        }

        const altPayResponse = await altPayApi?.createAltPayTransaction(
          dolyameRequest
        );

        const linkDolyame = altPayResponse?.Model?.ExtensionData?.Link;

        if (!linkDolyame || !altPayResponse?.Success) {
          if (altPayResponse?.ErrorCode) {
            state.reasonCode = altPayResponse.ErrorCode;
          }

          dolyame.isLoading = false;
          state.success = false;
          state.paymentMethod = PaymentMethodEnum.Dolyame;
          showMoreMethodsButton.value = false;
          emit("onFail", state.paymentMethod);
          process.value?.setMultipleByNames(["form", "result"]);

          return;
        }

        let dolyameWindow: Window | null | undefined;

        if (isSafari()) {
          modal!
            .openModal<any, any, any>({
              component: WaitLink,
              model: {
                link: linkDolyame,
                baseUrl: environment.orders.baseUrl,
                isDolyame: true,
              },
              options: {
                type: ModalType.Default,
                hideClose: true,
                noPadding: true,
                fullscreenMode: false,
              },
            })
            .then((result) => {
              if (result?.status === "canceled") {
                dolyameCanceled();
              }
            });
        } else {
          if (!isInstagramWebView()) {
            dolyameWindow = openWindowCentered(linkDolyame, "_blank", 460, 820);
          } else {
            window.location.href = linkDolyame;
          }
        }

        window.addEventListener("message", (event) => {
          if (
            event.origin === environment.orders.baseUrl &&
            event.data?.DolyameSuccess
          ) {
            dolyameWindow?.close();
            modal?.closeModal();
            state.success = event.data?.DolyameSuccess === "true";
            state.paymentMethod = PaymentMethodEnum.Dolyame;
            emit(state.success ? "onSuccess" : "onFail", state.paymentMethod);
            showMoreMethodsButton.value = false;
            process.value?.setMultipleByNames(["form", "result"]);
          }
        });

        const deviceDetector = new DeviceDetector();

        if (!isSafari()) {
          modal!
            .openModal<any, any, any>({
              component: WaitBottomSheet,
              model: {},
              options: {
                type: deviceDetector.isMobileSbpSpecific
                  ? ModalType.SlideFromBottom
                  : ModalType.Default,
                hideClose: true,
                noPadding: true,
                fullscreenMode: false,
              },
            })
            .then((result) => {
              if (result?.status == "canceled") {
                Logger.LogInfo("User has cancelled Dolyame waiting");
                dolyameCanceled();
              }
            });
        }

        dolyame.isLoading = false;
      } catch (err) {
        dolyame.isLoading = false;
        state.success = false;
        state.paymentMethod = PaymentMethodEnum.Dolyame;
        showMoreMethodsButton.value = false;
        emit("onFail", state.paymentMethod);
        process.value?.setMultipleByNames(["form", "result"]);
      }
    }

    function changeBinInfoMX(binInfo: BinInfoResponseModel) {
      if (!isInstallmentCard.value) {
        return;
      }

      formInstallmentMX.binInfo = binInfo;
      formInstallmentMX.isBankDataAvailable = binInfo.IsCardAllowed as boolean;
    }

    function onValidMX(isValid: boolean) {
      if (!isInstallmentCard.value) {
        return;
      }

      formInstallmentMX.isCardDataValid = isValid;
    }

    async function payByInstallment() {
      await payByCard({
        cardData: formInstallmentMX.cardData as CardData,
        cardName: CardUtils.getCardType(
          formInstallmentMX.cardData?.cardNumber.toString() as string
        ),
        bankName: formInstallmentMX.binInfo?.BankName as string,
        cvvRequired: true,
      });
    }

    async function payByCard({
      cardData,
      cardName,
      bankName,
      cvvRequired,
    }: {
      cardData: CardData;
      cardName: string;
      bankName: string;
      cvvRequired: boolean;
    }): Promise<void> {
      let validators:
        | Partial<
            Record<CardFields, (...args: any[]) => ValidationError | null>
          >
        | undefined = undefined;
      if (!cvvRequired) {
        validators = {
          cvv(): ValidationError | null {
            return null;
          },
        };
      }

      // state.reasonCode = null;
      cardPay.isLoading = true;
      loader?.showLoader();

      monitoring?.sendAmplitudeEvent(AmplitudeEvents.PayCard, {
        cardName: cardName,
        bankName: bankName,
      });

      statistPayEvent(PaymentMethodsStatist.Card, bankName);

      const errorPayResponse: PayResponse = {
        Success: false,
      } as PayResponse;

      try {
        let metadata: any;
        if (runtimeConfiguration.bayonetApiKey) {
          const bayonetScriptLoader = new BayonetScriptLoader({
            bayonetApiKey: runtimeConfiguration.bayonetApiKey,
          });

          metadata = {
            BayonetFingerprintToken: (await bayonetScriptLoader.executeScript())
              .bayonetFingerprintToken,
          };
        }

        let callbackResult: PayResponse | undefined =
          await appService?.payment?.payByCard(
            appService?.getOrderOptions().publicOrderId,
            cardData,
            appService?.getOrderOptions()?.isOrder,
            appService.email.value,
            appService.orderFormOptions.value.saveCard,
            validators,
            metadata,
            +(formInstallmentMX?.period as string) !== 1
              ? +(formInstallmentMX?.period as string)
              : undefined
          );
        state.paymentMethod = PaymentMethodEnum.Card;

        if (
          isInstallmentCard.value &&
          !callbackResult?.Success &&
          (callbackResult as PayResponse)?.ErrorCode
        ) {
          await onSuccessCard({
            ...errorPayResponse,
            Model: {
              ...callbackResult.Model,
              ReasonCode: (callbackResult as PayResponse)?.ErrorCode,
            },
          });
        }

        if (callbackResult) {
          await onSuccessCard(callbackResult);
        } else {
          await onSuccessCard(errorPayResponse);
          await onChangePaymentMethod(PaymentMethodEnum.Card);
        }
      } catch (e) {
        Logger.LogError("catch", e);
        state.paymentMethod = PaymentMethodEnum.Card;
        await onChangePaymentMethod(PaymentMethodEnum.Card);
        await onSuccessCard(errorPayResponse);
      }
    }

    async function payBySom() {
      statistPayEvent(PaymentMethodsStatist.SOM);

      som.isLoading = true;

      try {
        const somCanceled = () => {
          Logger.LogInfo("User has cancelled Som waiting");
          som.isLoading = false;
        };

        let somWindow: Window | null | undefined;

        const somPayResponse = await appService
          .getPayment()
          .payByAltPay(
            appService?.getOrderOptions(),
            AltPayType.Som,
            appService.orderFormOptions.value.saveCard
          );

        const isError =
          !somPayResponse?.Model?.ExtensionData?.Link ||
          !somPayResponse?.Model?.TransactionId ||
          !somPayResponse?.Success;

        if (isError) {
          if (somPayResponse?.ErrorCode) {
            state.reasonCode = somPayResponse?.ErrorCode;
          }
          state.success = false;
          state.paymentMethod = PaymentMethodEnum.Som;
          emit("onFail", PaymentMethodEnum.Som);
          process.value?.setMultipleByNames(["form", "result"]);
          return;
        }

        if (!isTelegram()) {
          if (isSafari()) {
            modal!
              .openModal<any, any, any>({
                component: WaitLink,
                model: {
                  link: somPayResponse?.Model?.ExtensionData?.Link,
                  baseUrl: environment.orders.baseUrl,
                },
                options: {
                  type: ModalType.Default,
                  hideClose: true,
                  noPadding: true,
                  fullscreenMode: false,
                },
              })
              .then((result) => {
                if (result?.status === "canceled") {
                  somCanceled();
                }
              });
          } else {
            if (!isInstagramWebView()) {
              somWindow = openWindowCentered(
                somPayResponse?.Model?.ExtensionData?.Link,
                "_blank",
                460,
                820
              );
            } else {
              window.location.href = somPayResponse?.Model?.ExtensionData
                ?.Link as string;
            }
          }
        } else {
          telegramRedirect(
            somPayResponse?.Model?.ExtensionData?.Link as string
          );
        }

        window.addEventListener("message", (event) => {
          if (
            event.origin === environment.orders.baseUrl &&
            event.data?.Success
          ) {
            somWindow?.close();
            modal!.closeModal();

            if (event.data?.Success === "true") {
              state.success = true;
              emit("onSuccess", PaymentMethodEnum.Som);
            } else {
              state.success = false;
              emit("onFail", PaymentMethodEnum.Som);
            }

            state.paymentMethod = PaymentMethodEnum.Som;
            process.value?.setMultipleByNames(["form", "result"]);
          }
        });

        const deviceDetector = new DeviceDetector();

        if (!isSafari()) {
          modal!
            .openModal<any, any, any>({
              component: WaitBottomSheet,
              model: {
                isSom: true,
              },
              options: {
                type: deviceDetector.isMobileSbpSpecific
                  ? ModalType.SlideFromBottom
                  : ModalType.Default,
                hideClose: true,
                noPadding: true,
                fullscreenMode: false,
              },
            })
            .then((result) => {
              if (result?.status == "canceled") {
                Logger.LogInfo("User has cancelled Som waiting");
                somCanceled();
              }
            });
        }
      } catch (err) {
        som.isLoading = false;
      }
    }

    async function on3ds(payResponse: PayResponse) {
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.ThreeDs);

      const prepareMd = Checkout.prepareMD({
        SuccessUrl: environment.orders.baseUrl + environment.orders.resultPage,
        FailUrl: environment.orders.baseUrl + environment.orders.resultPage,
        TransactionId: Number(payResponse?.Model?.TransactionId),
        ThreeDsCallbackId: String(payResponse?.Model?.ThreeDsCallbackId),
      });

      const termUrl =
        runtimeConfiguration.api.cpHost +
        environment.api.endpoints.threeDsCallback;

      const modalResult = await modal?.openModal<
        Ref<typeof ThreeDs>,
        {
          Success: boolean;
          Model: any;
        },
        {
          payResponse: PayResponse;
          termUrl: string;
          prepareMd: string;
        }
      >({
        component: shallowRef(ThreeDs),
        model: {
          payResponse,
          prepareMd: String(prepareMd),
          termUrl,
        },
        options: {
          fullscreenMode: true,
        },
      });

      state.success = !!modalResult?.Success;

      state.reasonCode =
        !modalResult?.Success && modalResult?.Model?.ReasonCode
          ? modalResult?.Model?.ReasonCode
          : null;
      showMoreMethodsButton.value =
        state.paymentMethod === PaymentMethodEnum.Card;

      emit(
        state.success ? "onSuccess" : "onFail",
        state.paymentMethod,
        payResponse?.Model?.TransactionId
      );

      appService?.getOrderOptions()?.update({
        paid: state.success,
        transactionId: modalResult?.Model?.TransactionId,
        accountId: (modalResult?.Model as any)?.AccountId || "",
      });

      if (cardForm.value) {
        cardForm.value.reset();
      }

      if (typeof modalResult !== "undefined") {
        if (state.paymentMethod && state.paymentMethod === "card") {
          process.value?.setMultipleByNames(["payment", "result"]);
        } else {
          process.value?.setMultipleByNames(["form", "result"]);
        }
      } else {
        process.value?.setActiveProcessByName("form");
        state.paymentMethod = null;
      }
      checkPaySystems();
      checkPayLength(!state.success);

      if (yandexPay.isAvailable) {
        await reinitializeYandexPaySession();
      }
    }

    function onReceipt(email: string) {
      Logger.LogInfo("email", email);
      appService.email.value = email;
      appService?.getOrderOptions()?.update({
        email: email,
      });

      monitoring?.sendAmplitudeEvent(AmplitudeEvents.SendReceipt, {
        email: email,
      });
    }

    function onShowEmail(showEmailValue: boolean) {
      Logger.LogInfo("onShowEmail", showEmailValue);
      showEmailField.value = showEmailValue;
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.Receipt);
      if (!showEmailValue) {
        appService.email.value = "";
        appService?.getOrderOptions()?.update({
          email: "",
        });
      }
    }

    const onSaveCard = function (saveCard: boolean) {
      appService.orderFormOptions.value.saveCard = saveCard;
      if (saveCard) {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.SaveCardOn);
      } else {
        monitoring?.sendAmplitudeEvent(AmplitudeEvents.SaveCardOff);
      }
    };

    const onShowSaveCardHint = (show: boolean) => {
      showSaveCardTooltip.value = show;
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.SaveCardHint);
    };

    const onShowForceSaveCardHint = (show: boolean) => {
      showForceSaveCardTooltip.value = show;
      monitoring?.sendAmplitudeEvent(AmplitudeEvents.ForceSaveCardHint);
    };

    function sendStatistSbpPay() {
      statistPayEvent(PaymentMethodsStatist.SbpPay);
    }

    function sbpPayResponse(result: {
      status: SbpResultStatus;
      errorCode?: number;
    }) {
      sbp.isLoading = false;
      state.paymentMethod = PaymentMethodEnum.SBP;

      function gotoResultWithError(statusCode: number) {
        process.value?.setMultipleByNames(["form", "result"]);
        state.success = false;
        state.reasonCode = statusCode;
      }

      switch (result?.status) {
        case SbpResultStatus.Fail: {
          gotoResultWithError(5204);
          break;
        }
        case SbpResultStatus.Success: {
          state.success = true;
          emit("onSuccess", state.paymentMethod);
          break;
        }
        case SbpResultStatus.Canceled: {
          gotoResultWithError(5005);
          break;
        }

        case SbpResultStatus.Declined: {
          gotoResultWithError(5204);
          break;
        }
      }

      if (result?.errorCode) {
        gotoResultWithError(result?.errorCode);
      }
    }

    function onQrPay() {
      sbp.isLoading = true;
    }

    function onChange({
      cardName,
      bankName,
      cardData,
    }: {
      cardName: string;
      bankName: string;
      cardData: CardData;
    }) {
      cardNameVal = cardName;
      cardNumber.value = cardData.cardNumber;
      if (!cardNumber.value || cardNumber.value === "null") {
        isCardNumberCountryChanged.value = false;
      }

      if (cardInstallment.isAvailable && !isInstallmentCard.value) {
        cardDataService.cardData = cardData;
      }

      if (!isInstallmentCard.value) {
        return;
      }

      monitoring?.sendAmplitudeEvent(AmplitudeEvents.InsertCardData, {
        cardName: cardName,
        bankName: bankName,
      });

      formInstallmentMX.cardData = cardData;
    }

    function onBankInfoChanged(
      bankInfo: { CountryCode: number; CardType: CardType } | null
    ): void {
      isCardNumberCountryChanged.value = true;
      cardNumberCountry.value = bankInfo?.CountryCode;
      isMirCard.value =
        bankInfo?.CardType === CardType.mir || cardNameVal === CardType.mir;
    }

    return {
      cardForm,
      loader,
      cardPay,
      yandexPay,
      applePay,
      googlePay,
      sbp,
      tinkoffInstallment,
      tinkoffCredit,
      tinkoffPay,
      sberPay,
      dolyame,
      amount,
      currency,
      currencySymbol,
      process,
      yandexPayButton,
      onChangePaymentMethod,
      onReceipt,
      onShowEmail,
      onSaveCard,
      onShowSaveCardHint,
      onShowForceSaveCardHint,
      payByCard,
      payBySom,
      payByApplePay,
      payByGooglePay,
      startTinkoffInstallmentProcess,
      payByTinkoffPay,
      payBySberPay,
      payByDolyame,
      onChange,
      state,
      showEmailField,
      offerLink,
      privacyPoliceLink,
      agreementLink,
      staticOfferLink,
      staticAgreementLink,
      receiptEmail: appService.email,
      shouldShowSaveCard,
      showSaveCardTooltip,
      shouldShowForceSaveHint,
      showForceSaveCardTooltip,
      saveCardObject: appService.orderFormOptions,
      isCvvRequired,
      skipExpiryValidation,
      showMoreMethodsButton,
      showCheckboxesCardPayment,
      sbpPayResponse,
      onQrPay,
      payBySpei,
      goToCardInstallment,
      goToPaymentProcess,
      onSuccessSpei,
      showQRCode,
      qrImage,
      waitEnabled,
      spei,
      cardInstallment,
      terminalName,
      altPayResponseData:
        altPayResponseData as unknown as CreateAltPayOrderResponse,
      isSpei,
      som,
      availablePayCount,
      isMxTranslation: runtimeConfiguration.isCustomMXTranslation,
      sendStatistSbpPay,
      isInstallmentCard,
      isInstallmentAlert,
      periods,
      formInstallmentMX,
      payByInstallment,
      changeBinInfoMX,
      onValidMX,
      selectCashPayment,
      payByCash,
      CashMethods,
      v$,
      cashFormRules,
      cashSettings,
      isCashSumAvailable,
      isValidCashForm,
      barcodeCashLink,
      barcodeCashLinkOpened,
      onOpenBarcodeLink,
      onBankInfoChanged,
      isCardNumberCountryChanged,
      cardNumberCountry,
      isMirCard,
      cardNumber,
      locale,
      defaultCardData,
      getFormattedAmount,
      payByTinkoffCredit,
      cashOxxo,
      cashCStores,
      cashPharmacy,
      cash,
      selectedCashMethod,
      cashStoreName,
    };
  },
});
</script>
<style src="./PaymentProcess.scss" scoped lang="scss"></style>
